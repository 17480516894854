<template>
  <div class="main-page">
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="md:col-9 sm:col-12 comp-grid">
            <div class="card nice-shadow-18">
              <div>
                <template v-if="!loading">
                  <div class="grid">
                    <div class="text-2xl text-primary font-bold mb-3">
                      {{ formData.third_party_agencies_grants_grant_name }}
                    </div>
                    <div class="col-12" v-if="formData.grant_additional_info">
                      <form
                        ref="observer"
                        tag="form"
                        @submit.prevent="startRecordUpdate()"
                      >
                        <!--[form-content-start]-->
                        <div class="grid">
                          <div
                            v-if="formData.grant_additional_info"
                            class="col-12"
                          >
                            <div class="grid">
                              <div
                                v-for="(definition, index) in JSON.parse(
                                  formData.grant_additional_info
                                )"
                                :key="index"
                                class="col"
                              >
                                <div class="">
                                  <div class="mb-2 font-bold text-sm">
                                    {{ definition.label }}
                                  </div>
                                  <div class="">
                                    <InputText
                                      :ref="
                                        'ctrladditional_form_definition' + index
                                      "
                                      v-model="definition.value"
                                      :label="definition.label"
                                      :type="definition.datatype"
                                      :placeholder="definition.label"
                                      class="w-full"
                                      @change="
                                        () => {
                                          // print(formData.grant_additional_info);
                                          let additional_definition =
                                            JSON.parse(
                                              formData.grant_additional_info
                                            );
                                          let pos = additional_definition
                                            .map((val) => val.label)
                                            .indexOf(definition.label);
                                          additional_definition[pos].value =
                                            definition.value;

                                          formData.grant_additional_info =
                                            JSON.stringify(
                                              additional_definition
                                            );
                                          // print(formData.grant_additional_info);
                                        }
                                      "
                                    >
                                    </InputText>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--[form-content-end]-->
                        <div v-if="showSubmitButton" class="text-center my-3">
                          <Button
                            type="submit"
                            :label="$t('save_and_continue')"
                            icon="pi pi-send"
                            :loading="saving"
                          />
                        </div>
                      </form>
                      <slot :submit="submit" :saving="saving"></slot>
                    </div>
                    <div v-else class="text-center">
                      <div
                        class="p-3 my-3 text-500 text-lg font-medium text-center"
                      >
                        {{ $t("no_additional_fields_found") }}
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="p-3 text-center">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import { EditPageMixin } from "../../mixins/editpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "editApplicantselectedgrantsPage",
  components: {},
  mixins: [PageMixin, EditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicant_selected_grants",
    },
    idName: {
      type: String,
      default: "applicant_selected_grant_id",
    },
    routeName: {
      type: String,
      default: "applicant_selected_grantsedit",
    },
    pagePath: {
      type: String,
      default: "applicant_selected_grants/edit",
    },
    apiPath: {
      type: String,
      default: "applicant_selected_grants/edit",
    },
  },
  data() {
    return {
      formData: {
        third_party_agency_id: "",
        third_party_agencies_grant_id: "",
        grant_additional_info: "",
      },
      submitted: false,
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Edit Applicant Selected Grants";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["applicant_selected_grants/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("applicant_selected_grants/setCurrentRecord", value);
      },
    },
    apiUrl: function () {
      if (this.id) {
        return this.apiPath + "/" + encodeURIComponent(this.id);
      }
      return this.apiPath;
    },
  },
  validations() {
    let formData = {
      third_party_agency_id: {},
      third_party_agencies_grant_id: {},
      grant_additional_info: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("applicant_selected_grants", ["updateRecord", "fetchRecord"]),
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Please complete the form", "Form is invalid", "error");
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      let data = { id, url, payload };
      this.updateRecord(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(this.msgAfterUpdate);
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        third_party_agency_id: "",
        third_party_agencies_grant_id: "",
        grant_additional_info: "",
      };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
